import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { URL } from '../config';
export function Home() {
  return (
    <div className="container">
      <Head>
        <title>Organized – Decluttering your way to an organized life</title>
      </Head>
      <main className="frontpage">
        <div className="intro">
          <div className="introText">
            <h1>Get ready to start your decluttering journey.</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industrys standard dummy text
              ever since the 1500s.
            </p>
            <p>
              Download now for
              <Link href={URL.APP_STORE}>
                <a>iOS</a>
              </Link>
              and
              <Link href={URL.PLAY_STORE}>
                <a>Android</a>
              </Link>
            </p>
          </div>
          <div className="introImg">
            <div className="phone">
              <Image
                src="/images/phone.png"
                alt="Phone with Screenshot"
                width="320"
                height="372"
              />
            </div>
            <div className="shadow">
              <Image
                src="/images/shadow.png"
                alt="shadow logo"
                width="320"
                height="330"
              />
            </div>
          </div>
        </div>
        <div className="screens">
          <div className="screen">
            <div className="screenImage">
              <Image
                src="/images/phone1.png"
                alt="Phone with Screenshot"
                width="313"
                height="621"
              />
            </div>
            <div className="screenText">
              <h2>
                Let&rsquo;s get started! Check of tasks as you complete each
                category.
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industrys standard dummy text
                ever since the 1500s.
              </p>
            </div>
          </div>
          <div className="screen reverse">
            <div className="screenImage">
              <Image
                src="/images/phone2.png"
                alt="Phone with Screenshot"
                width="313"
                height="621"
              />
            </div>
            <div className="screenText">
              <h2>PULL OUT EVERYTHING</h2>
              <p>
                Locate every piece you have in a category, from all over the
                house, and get it out in the open
              </p>
            </div>
          </div>
          <div className="screen">
            <div className="screenImage">
              <Image
                src="/images/phone3.png"
                alt="Phone with Screenshot"
                width="313"
                height="621"
              />
            </div>
            <div className="screenText">
              <h2>SORT AND GROUP</h2>
              <p>
                Group similar items, and assess all the stuff you have. A good
                rule is to keep what you use, need, and love.
              </p>
            </div>
          </div>
          <div className="screen reverse">
            <div className="screenImage">
              <Image
                src="/images/phone4.png"
                alt="Phone with Screenshot"
                width="313"
                height="621"
              />
            </div>
            <div className="screenText">
              <h2>Get Achievements</h2>
              <p>
                Locate every piece you have in a category, from all over the
                house, and get it out in the open
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
